/**
 * Created by huzeyin on 2020/5/12.
 */
import request from '../utils/request'

export function getApplications (user_id) {
  return request.post('./index.php', {
    function: 'getApplications',
    user_id
  })
}

export function getProjects () {
  return request.post('./index.php', {
    function: 'getProjects'
  })
}

export function getTestCentersAndEventsByProject (project_id) {
  return request.post('./index.php', {
    function: 'getTestCentersAndEventsByProject',
    project_id
  })
}

export function addApplication (user_id, project_id, project_event_id, project_test_center_id, channel, reason, get_from) {
  return request.post('./index.php', {
    function: 'addApplication',
    user_id,
    project_id,
    project_event_id,
    project_test_center_id,
    channel,
    reason,
    get_from
  })
}

export function getSignPackage () {
  return request.post('./index.php', {
    function: 'getSignPackage'
  })
}

export function createLoginPin (mobile) {
  return request.post('./index.php', {
    function: 'createLoginPin',
    mobile
  })
}

export function verifyLoginPin (mobile, pin, open_id) {
  return request.post('./index.php', {
    function: 'verifyLoginPin',
    mobile,
    pin,
    open_id
  })
}

export function getOpenId (code) {
  return request.post('./index.php', {
    function: 'getOpenId',
    code
  })
}

export function getWXUserInfo (open_id, access_token) {
  return request.post('./index.php', {
    function: 'getWXUserInfo',
    access_token,
    open_id
  })
}

export function getStudentInfoByUnionid (unionid) {
  return request.post('./index.php', {
    function: 'getStudentInfoByUnionid',
    unionid
  })
}

export function updateStudentInfo (id, params) {
  return request.post('./index.php', {
    function: 'updateStudentInfo',
    id,
    student_email: params.student_email,
    student_lastName: params.student_lastName,
    student_givenName: params.student_givenName,
    student_lastName_pinyin: params.student_lastName_pinyin,
    student_givenName_pinyin: params.student_givenName_pinyin,
    student_gender: params.student_gender,
    student_graduation: params.student_graduation,
    student_mobile: params.student_mobile,
    student_guardian_first_mobile: params.student_guardian_first_mobile,
    student_guardian_first_email: params.student_guardian_first_email,
    student_pwd: params.student_pwd
  })
}

export function loginByOpenId (open_id) {
  return request.post('./index.php', {
    function: 'loginByOpenId',
    open_id
  })
}

export function loginOnWebByWechat (key, open_id) {
  return request.post('./index.php', {
    function: 'loginOnWebByWechat',
    key,
    open_id
  })
}

export function createProductOrder (user_id, project_id, project_event_id, project_test_center_id, application_id) {
  return request.post('./index.php', {
    function: 'createProductOrder',
    user_id,
    project_id,
    project_event_id,
    project_test_center_id,
    application_id
  })
}

export function wx_pay (order_id, user_id, open_id) {
  return request.post('./index.php', {
    function: 'wx_pay',
    order_id,
    user_id,
    open_id
  })
}

export function getApplication (application_id) {
  return request.post('./index.php', {
    function: 'getApplication',
    application_id
  })
}

export function updateUserInfo (user_id, param, value) {
  return request.post('./index.php', {
    function: 'updateUserInfo',
    user_id,
    param,
    value
  })
}

export function getEvents (language) {
  return request.post('./index.php', {
    function: 'getEvents',
    language
  })
}

export function getEvent (event_id) {
  return request.post('./index.php', {
    function: 'getEvent',
    event_id
  })
}

export function getProjectsByEvent (event_id) {
  return request.post('./index.php', {
    function: 'getProjectsByEvent',
    event_id
  })
}

export function updatePersonalInfo (mobile, name, id_number, user_id) {
  return request.post('./index.php', {
    function: 'updatePersonalInfo',
    mobile,
    name,
    id_number,
    user_id
  })
}

export function getUser (user_id) {
  return request.post('./index.php', {
    function: 'getUser',
    user_id
  })
}

export function cancelApplication (application_id) {
  return request.post('./index.php', {
    function: 'cancelApplication',
    application_id
  })
}

export function getAccount (account_id) {
  return request.post('./index.php', {
    function: 'getAccount',
    account_id
  })
}

export function getProject (project_code) {
  return request.post('./index.php', {
    function: 'getProject',
    project_code
  })
}

export function addNewApplication (params) {
  return request.post('./index.php', {
    function: 'addNewApplication',
    project_code: params.project_code,
    student_email: params.student_email,
    account_id: params.account_id,
    student_lastName: params.student_lastName,
    student_givenName: params.student_givenName,
    student_lastName_pinyin: params.student_lastName_pinyin,
    student_givenName_pinyin: params.student_givenName_pinyin,
    student_gender: params.student_gender,
    student_graduation: params.student_graduation,
    student_mobile: params.student_mobile,
    student_guardian_first_mobile: params.student_guardian_first_mobile,
    student_guardian_first_email: params.student_guardian_first_email,
    unionid: params.unionid,
    application_event: params.application_event
  })
}
export function getOpenBAA (id,student_id) {
  return request.post('./index.php', {
    function: 'getOpenBAA',
    id,
    student_id
  })
}
