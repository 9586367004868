<template>
  <div style="margin: 20px">
    <el-button v-if="source!='wx'" size="small" type="primary" @click="exportImage" style="margin-bottom: 20px">导出图片</el-button>
    <div class="cert-root">
      <div class="cert-container" id="cert">
        <img src="https://mainbucket.reachable-edu.com/baaCerBg.jpg" class="img-bg">
        <div class="student_name">{{ student_name }}</div>
        <div class="account_name">{{ account_name }}</div>
        <div class="baa_name">{{ baa_name }}</div>
        <div class="cert-date">{{ exam_date }}</div>
      </div>
    </div>
  </div>

</template>

<script>
// BAA证书，在小程序里面店家打开的，切勿删除
import {getOpenBAA} from '../../api/test'
import {formatDate} from '../../utils/date'
import * as htmlToImage from 'html-to-image';
import { toJpeg } from 'html-to-image'

export default {
  name: 'BAACert',
  data() {
    return {
      id: this.$route.query.id,
      student_id: this.$route.query.student_id,
      source:'',
      student_name: '',
      exam_date: '',
      baa_name: '',
      account_name: ''
    }
  },
  mounted() {
    //test
    if(this.$route.query.source){
      this.source = this.$route.query.source
    }
    // this.student_id = '5d564b1ea4640b1c158b456b'
    // this.id = '62482ab2a4640b3e268b4573'
    this.fetchData()
  },
  methods: {
    exportImage() {
      htmlToImage.toJpeg(document.getElementById('cert'), { quality: 0.95 })
          .then(function (dataUrl) {
            var link = document.createElement('a');
            var date = new Date()
            link.download = 'baa-'+date.getTime()+'.jpeg';
            link.href = dataUrl;
            link.click();
          });
    },
    fetchData() {
      getOpenBAA(this.id, this.student_id).then((res) => {
        let code = res.data.code
        // if (code == 0) {
        let result = res.data.data
        this.student_name = result.student_info.student_lastName_pinyin + ' ' + result.student_info.student_givenName_pinyin
        this.baa_name = result.name
        this.account_name = result.student_info.account_name
        let examine_time = result.app_info.examine_time
        let temp_time = examine_time * 1000
        let date = new Date(temp_time)
        this.exam_date = formatDate(date, 'yyyy.MM.dd')
        // } else {
        //   this.$message({
        //     message: res.data.msg,
        //     type: 'error'
        //   })
        // }
      })
    },
  }
}
</script>

<style lang="less" scoped>

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../../assets/font/Montserrat-Regular.otf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../../assets/font/Montserrat-SemiBold.otf");
}


.cert-root {
  box-sizing: border-box;
  width: 300mm;
  height: 210mm;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.cert-container {
  position: relative;

  .img-bg {
    width: 297mm;
    height: 208.7mm;
  }

  .student_name {
    position: absolute;
    font-size: 48px;
    top: 270px;
    left: 80px;
    font-weight: 500;
    color: #2050bb;
    font-family: Montserrat-SemiBold;
  }

  .account_name {
    position: absolute;
    font-size: 28px;
    top: 330px;
    left: 80px;
    font-weight: 500;
    color: #2050bb;
      font-family: Montserrat-Regular;
  }

  .baa_name {
    position: absolute;
    font-size: 30px;
    top: 415px;
    left: 80px;
    font-weight: 500;
    color: #2050bb;
    font-family: Montserrat-SemiBold;
  }

  .cert-date {
    position: absolute;
    font-size: 18px;
    bottom: 150px;
    left: 100px;
    font-weight: 500;
    color: #333333;
    font-family: Montserrat-Regular;
  }
}
</style>
