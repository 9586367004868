import Vue from 'vue'
import Router from 'vue-router'

import Root from '../pages/Root.vue'

import BAACert from '../pages/baa/BAACert.vue'

import { getUserId, getUnionId } from '../utils/store'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component:Root
    },
    {
      path: '/baacert',
      name: 'BAACert',
      component: BAACert,
      meta: {
        requiresAuth: false,
        title:'baa-cert'
      }
    }
  ]
})
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title= to.meta.title
  }
  next()
  // let redirectUrl = encodeURIComponent()
  // const userId = getUserId()
  // const open_id = getUnionId()
  // if (to.meta.requiresAuth && (!userId || !open_id)) {
  //   next({
  //     path: '/entry_main'
  //   })
  // } else {
  //   next()
  // }
})

// // eslint-disable-next-line no-unused-expressions,no-sequences
// router.afterEach((to, from, next) => {
//   window, scrollTo(0, 0)
// })
export default router
